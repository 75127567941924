import React from 'react';
import { Link } from 'react-router-dom';
 
const Navigation = () => {
    return (
       <div className='sidenav'>
         <div className='artist-name'><center>Francine Ballet</center></div>
         <nav>
          <Link to="/">Home </Link>
          <Link to="/beelden">Beelden </Link>
          <Link to="/loopbaan">Loopbaan </Link>
          <Link to="/expositie">Expositie </Link>
          <Link to="/contact">Contact </Link>
          </nav>
       </div>
    );
}
 
export default Navigation;