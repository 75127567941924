
import React from 'react';
import PageTitle from '../Components/PageTitle';
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";

const imagesBeelden = [
    {
        original: 'https://francineballet.blob.core.windows.net/images/dialoog36.JPG',
        thumbnail: 'https://francineballet.blob.core.windows.net/thumbnails/tndialoog36.JPG',
        description: 'Dialoog - zandsteen'
    },
    {
        original: 'https://francineballet.blob.core.windows.net/images/drie-eenheid b.jpg',
        thumbnail: 'https://francineballet.blob.core.windows.net/thumbnails/tndrie-eenheid b.jpg',
        description: 'Drie-eenheid - zandsteen'
    },
    {
        original: 'https://francineballet.blob.core.windows.net/images/therapeut.jpg',
        thumbnail: 'https://francineballet.blob.core.windows.net/thumbnails/tntherapeut.jpg',
        description: 'Therapeut - sokkel ebbenhout - carrara - zandsteen'
    },
    {
        original: 'https://francineballet.blob.core.windows.net/images/eerstgeborene.jpg',
        thumbnail: 'https://francineballet.blob.core.windows.net/thumbnails/tneerstgeborene.jpg',
        description: 'Eerstgeborene - marmer'
    },
    {
        original: 'https://francineballet.blob.core.windows.net/images/elisabeth.jpg',
        thumbnail: 'https://francineballet.blob.core.windows.net/thumbnails/tnelisabeth.jpg',
        description: 'Portret - Elisabeth - teakhout'
    },
    {
        original: 'https://francineballet.blob.core.windows.net/images/fusie.jpg',
        thumbnail: 'https://francineballet.blob.core.windows.net/thumbnails/tnfusie.jpg',
        description: 'Samen - marmer'
    },
    {
        original: 'https://francineballet.blob.core.windows.net/images/gelukkig zijn.jpg',
        thumbnail: 'https://francineballet.blob.core.windows.net/thumbnails/tngelukkig zijn.jpg',
        description: 'Gelukkig zijn - zandsteen'
    },
    {
        original: 'https://francineballet.blob.core.windows.net/images/weerzien.jpg',
        thumbnail: 'https://francineballet.blob.core.windows.net/thumbnails/tnweerzien.jpg',
        description: 'Weerzien - zandsteen'
    },
    {
        original: 'https://francineballet.blob.core.windows.net/images/gezin.jpg',
        thumbnail: 'https://francineballet.blob.core.windows.net/thumbnails/tngezin.jpg',
        description: 'Gezin - draaiend op sokkel - hout - verschillende houtsoorten'
    },
    {
        original: 'https://francineballet.blob.core.windows.net/images/intimiteit.jpg',
        thumbnail: 'https://francineballet.blob.core.windows.net/thumbnails/tnintimiteit.jpg',
        description: 'Intimiteit - marmer'
    },
    {
        original: 'https://francineballet.blob.core.windows.net/images/samen anders.jpg',
        thumbnail: 'https://francineballet.blob.core.windows.net/thumbnails/tnsamen anders.jpg',
        description: 'Samen anders - carrara - Gentstraat, Waasmunster'
    },
    {
        original: 'https://francineballet.blob.core.windows.net/images/dragen en gedragen worden.jpg',
        thumbnail: 'https://francineballet.blob.core.windows.net/thumbnails/tndragen en gedragen worden.jpg',
        description: 'Dragen en gedragen worden - hout (wengé)'
    },
    {
        original: 'https://francineballet.blob.core.windows.net/images/gesprek.jpg',
        thumbnail: 'https://francineballet.blob.core.windows.net/thumbnails/tngesprek.jpg',
        description: 'Ontmoeting - hout'
    },
    {
        original: 'https://francineballet.blob.core.windows.net/images/moeder en kind.jpg',
        thumbnail: 'https://francineballet.blob.core.windows.net/thumbnails/tnmoeder en kind.jpg',
        description: 'Moeder en kind - brons'
    },
    {
        original: 'https://francineballet.blob.core.windows.net/images/ontmoeting.jpg',
        thumbnail: 'https://francineballet.blob.core.windows.net/thumbnails/tnontmoeting.jpg',
        description: 'Ontmoeting - hout - aformosa'
    },
    {
        original: 'https://francineballet.blob.core.windows.net/images/trouw.jpg',
        thumbnail: 'https://francineballet.blob.core.windows.net/thumbnails/tntrouw.jpg',
        description: 'Trouw - ebbenhout'
    },
    {
        original: 'https://francineballet.blob.core.windows.net/images/omsluiten.jpg',
        thumbnail: 'https://francineballet.blob.core.windows.net/thumbnails/tnomsluiten.jpg',
        description: 'Omsluiten - zandsteen'
    },
    {
        original: 'https://francineballet.blob.core.windows.net/images/man en vrouw.jpg',
        thumbnail: 'https://francineballet.blob.core.windows.net/thumbnails/tnman en vrouw.jpg',
        description: 'Man en vrouw - 2 figuren - zandsteen'
    },
    {
        original: 'https://francineballet.blob.core.windows.net/images/bescherming.jpg',
        thumbnail: 'https://francineballet.blob.core.windows.net/thumbnails/tnbescherming.jpg',
        description: 'Bescherming - zandsteen'
    },
    {
        original: 'https://francineballet.blob.core.windows.net/images/echt paar 1.jpg',
        thumbnail: 'https://francineballet.blob.core.windows.net/thumbnails/tnecht paar 1.JPG',
        description: 'Echtpaar - zandsteen'

    },
    {
        original: 'https://francineballet.blob.core.windows.net/images/dragen en gedragen worden 5.jpg',
        thumbnail: 'https://francineballet.blob.core.windows.net/thumbnails/tndragen en gedragen worden 5.JPG',
        description: 'Dragen en gedragen worden - marmer'
    },
    {
        original: 'https://francineballet.blob.core.windows.net/images/hemel en aarde 1b.jpg',
        thumbnail: 'https://francineballet.blob.core.windows.net/thumbnails/tnhemel en aarde 1b.JPG',
        description: 'Hemel en aarde - zandsteen'
    },
    {
        original: 'https://francineballet.blob.core.windows.net/images/diva.jpg',
        thumbnail: 'https://francineballet.blob.core.windows.net/thumbnails/tndiva.JPG',
        description: 'Diva - zandsteen'
    },
    {
        original: 'https://francineballet.blob.core.windows.net/images/rustende moeder met kind.jpg',
        thumbnail: 'https://francineballet.blob.core.windows.net/thumbnails/tnrustende moeder.jpg',
        description: 'Moeder en kind - euville'
    },
    {
        original: 'https://francineballet.blob.core.windows.net/images/dialogerend paar.jpg',
        thumbnail: 'https://francineballet.blob.core.windows.net/thumbnails/tndialogerend paar.jpg',
        description: 'Dialogerend paar - 2 figuren - zandsteen'
    },
    {
        original: 'https://francineballet.blob.core.windows.net/images/zorgend paar.jpg',
        thumbnail: 'https://francineballet.blob.core.windows.net/thumbnails/tn_zorgend paar.jpg',
        description: 'Samenhorig - albast'
    },
]

const Beelden = () =>
{
    return(
        <div className="myPhotoCarousel">
            <PageTitle title="BEELDEN"/>
            <div className='page-content'>
                <ImageGallery items={imagesBeelden} />
            </div>
        </div>);
}

/* const Beelden2 = () => {
    return (
        <div>
            <PageTitle title="BEELDEN"/>
            <div className='page-content'>
                <img src={'https://francineballet.blob.core.windows.net/thumbnails/tndialoog36.JPG'} alt="Dialoog"/>
                <img src={'https://francineballet.blob.core.windows.net/thumbnails/tndrie-eenheid b.jpg'} alt="Drie-eenheid"/>
                <img src={'https://francineballet.blob.core.windows.net/thumbnails/tntherapeut.jpg'} alt="Therapeut"/>
                <img src={'https://francineballet.blob.core.windows.net/thumbnails/tneerstgeborene.jpg'} alt="Eerstgeborene"/>
                <img src={'https://francineballet.blob.core.windows.net/thumbnails/tnelisabeth.jpg'} alt="Elisabeth"/>
                <img src={'https://francineballet.blob.core.windows.net/thumbnails/tnfusie.jpg'} alt="Fusie"/>
                <img src={'https://francineballet.blob.core.windows.net/thumbnails/tngelukkig zijn.jpg'} alt="Gelukkig zijn"/>
                <img src={'https://francineballet.blob.core.windows.net/thumbnails/tnweerzien.jpg'} alt="Weerzien"/>
                <img src={'https://francineballet.blob.core.windows.net/thumbnails/tngezin.jpg'} alt="Gezin"/>
                <img src={'https://francineballet.blob.core.windows.net/thumbnails/tnintimiteit.jpg'} alt="Intimiteit"/>
                <img src={'https://francineballet.blob.core.windows.net/thumbnails/tnsamen anders.jpg'} alt="Samen anders"/>
                <img src={'https://francineballet.blob.core.windows.net/thumbnails/tndragen en gedragen worden.jpg'} alt="Dragen en gedragen worden"/>
                <img src={'https://francineballet.blob.core.windows.net/thumbnails/tngesprek.jpg'} alt="Gesprek"/>
                <img src={'https://francineballet.blob.core.windows.net/thumbnails/tnmoeder en kind.jpg'} alt="Moeder en kind"/>
                <img src={'https://francineballet.blob.core.windows.net/thumbnails/tnontmoeting.jpg'} alt="Ontmoeting"/>
                <img src={'https://francineballet.blob.core.windows.net/thumbnails/tntrouw.jpg'} alt="Trouw"/>
                <img src={'https://francineballet.blob.core.windows.net/thumbnails/tnomsluiten.jpg'} alt="Omsluiten"/>
                <img src={'https://francineballet.blob.core.windows.net/thumbnails/tnman en vrouw.jpg'} alt="Man en vrouw"/>
                <img src={'https://francineballet.blob.core.windows.net/thumbnails/tnbescherming.jpg'} alt="Bescherming"/>
                <img src={'https://francineballet.blob.core.windows.net/thumbnails/tnecht paar 1.JPG'} alt="Echtpaar 1"/>
                <img src={'https://francineballet.blob.core.windows.net/thumbnails/tndragen en gedragen worden 5.JPG'} alt="Dragen en gedragen worden 5"/>
                <img src={'https://francineballet.blob.core.windows.net/thumbnails/tnhemel en aarde 1b.JPG'} alt="Hemel en aarde 1b"/>
                <img src={'https://francineballet.blob.core.windows.net/thumbnails/tndiva.JPG'} alt="Diva"/>
                <img src={'https://francineballet.blob.core.windows.net/thumbnails/tnrustende moeder.jpg'} alt="Rustende moeder met kind"/>
                <img src={'https://francineballet.blob.core.windows.net/thumbnails/tnverbondenheid.jpg'} alt="Verbondenheid"/>
                <img src={'https://francineballet.blob.core.windows.net/thumbnails/tndialogerend paar.jpg'} alt="Dialogerend paar"/>
                <img src={'https://francineballet.blob.core.windows.net/thumbnails/tn_zorgend paar.jpg'} alt="Zorgend paar"/>
            </div>
        </div>
     );
} */

export default Beelden