
import React from 'react';
import PageTitle from '../Components/PageTitle';

const Contact = () => {
    return (
        <div>
           <PageTitle title="Contact"/>
           <div className='page-content'>
                <h2>Francine Ballet</h2>
                <img src={'https://francineballet.blob.core.windows.net/images/sien.jpg'} alt="Francine Ballet"/><br/>
                Francine Ballet<br/>
                Sportstraat 20<br/>
                9250 Waasmunster<br/>
                0032 (0)472 324073<br/>
            </div>
        </div>
     );
}

export default Contact