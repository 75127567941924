
import React from 'react';
import PageTitle from '../Components/PageTitle';

const blueHighlight = { 
    color: 'blue'
};

const Expositie = () => {
    return (
        <div>
           <PageTitle title="EXPOSITIE"/>
           <div className='page-content'>
           <img src={'https://francineballet.blob.core.windows.net/images/man en vrouw bis.jpg'} alt="Man en vrouw"/>
                <p style={blueHighlight}>Atelierbezoek is mogelijk op aanvraag.</p>
            </div>
        </div>
     );
}

export default Expositie