import React from 'react';
import PageTitle from '../Components/PageTitle';

const Loopbaan = () => {

    const whiteHighlight = { 
        color: 'white'
    };

    return (
        <div>
            <PageTitle title="LOOPBAAN"/>
            <div className='page-content'>
                <p><strong>Francine Ballet</strong> studeerde aan de Academie voor Schone Kunsten én het hoger Sint Lukas Instituut te Gent.
                    In het kader van een cultureel uitwisselingsproject met Oostenrijk, vervolmaakte ze haar opleiding aan de Academie van Salzburg waar ze o.l.v. Francesco Somaini werkte.
                    Ze gaf als vakleerkracht jarenlang les in de Plastische Kunsten en Kunstgeschiedenis en was tot 1995 ook verbonden aan de Centrale Examencommissie voor Plastische Kunsten te Brussel.
                </p>

                <p style={whiteHighlight}>Francine Ballet behaalde verschillende medailles en vermeldingen en prijzen :</p>
                <ul>
                    <li>Prijs van de stad Gent</li>
                    <li>Prijs van de stad Dendermonde</li>
                    <li>Pro Arte Christiana</li>
                    <li>Crève Coeur</li>
                    <li>Davidsfonds</li>
                    <li>Féderatie voor Blauwe Steen</li>
                    <li>Rotary</li>
                </ul>

                <p>Francine Ballet heeft zich de laatste jaren volop kunnen ontplooien naar wat haar drijft en boeit.
                In een tijdspanne van 35 jaar evolueerde haar werk van een vrij natuurgetrouwe figuratie naar een steeds eenvoudig wordende zuivere vormgeving.
                Haar werk doet denken aan bouwen : een stapelen van volumes die lijken te zwellen, elkaar dreigen te verdringen, maar telkens opnieuw groeien naar een gespannen evenwicht, waarbij elk volume zichzelf is én noodzakelijk.
                Telkens opnieuw is zij in haar werk op zoek naar evenwicht tussen het vatbare en het ontastbare van de geest.
                Haar kunst als uitdrukking van onmacht of een poging om uit te beelden wat niet te verwoorden valt.</p>

                <p style={whiteHighlight}>Ze nam deel aan verschillende groepstentoonstellingen en projecten :</p>

                <ul>                
                    <li>KB Gent 1975</li>
                    <li>Tussen 1976 en 1977 op verschillende locaties in België :</li>

                    <li>Stadhuis Brugge, Stadhuis Leuven, Stadhuis Oostende, Cultureel Centrum Hasselt, Warande Turnhout, Landcommanderij Alden Biesen Rijkhoven</li>
                    <li>St. Baafs Kathedraal Gent 1977</li>
                    <li>Basiliek Dadizele 1978</li>
                    <li>Domein " Ten Berg " Merelbeke 1978</li>
                    <li>Kunstkring Waasmunster 1979</li>
                    <li>ASLK Brussel 1986</li>
                    <li>Kasteel Blauwendael Waasmunster 1988</li>
                    <li>Kasteel Wissekerke Basel 2001</li>
                    <li>Kunstgalerij Venus Van Milo Hoboken 2002</li>
                    <li>Kunstgalerij Kunstkring De Castelijn Hingene 2003</li>
                    <li>Project voor de Kunstkring en de gemeente Waasmunster : Beeld " Samen Anders " inhuldiging september 2006.</li>
                    <li>Kasteel Blauwendael Kunstkring Waasmunster 2009</li>
                    <li>Kunst op 't Fabriek, Natuursteenbedrijf Carrara</li>
                    <li>St. Gillis Waas 2009</li>
                    <li>Aardenburgse Zomerkunstententoonstelling,</li>
                    <li>Nederland 2010</li>
                    <li>Kunst op 't Fabriek, Natuursteenbedrijf Carrara</li>
                    <li>St. Gillis Waas 2011</li>

                </ul>

                <p style={whiteHighlight}>Francine Ballet heeft zich de laatste jaren volop kunnen ontplooien naar wat haar drijft en boeit.
                In een tijdspanne van 35 jaar evolueerde haar werk van een vrij natuurgetrouwe figuratie naar een steeds eenvoudig wordende zuivere vormgeving.
                Haar werk doet denken aan bouwen : een stapelen van volumes die lijken te zwellen, elkaar dreigen te verdringen, maar telkens opnieuw groeien naar een gespannen evenwicht, waarbij elk volume zichzelf is én noodzakelijk.
                Telkens opnieuw is zij in haar werk op zoek naar evenwicht tussen het vatbare en het ontastbare van de geest.
                Haar kunst als uitdrukking van onmacht of een poging om uit te beelden wat niet te verwoorden valt.
                </p>
            </div>
        </div>
     );
}

export default Loopbaan
