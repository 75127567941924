import React from 'react';
import PageTitle from '../Components/PageTitle';

const Home = () => {
    return (
        <div>
           <PageTitle title="BEELDEN" />
           <div className='page-content'>
               <img src={'https://francineballet.blob.core.windows.net/thumbnails/therapeutklein.jpg'} alt="Therapeut"/>
           </div>
        </div>
     );
}

export default Home