import React from "react";
import { Routes, Route } from 'react-router-dom';
import Navigation from './Components/Navigation';
//import { FaHome, FaGem, FaHeart } from 'react-icons/fa';
//import { ProSidebar, SidebarHeader, Menu, MenuItem, SidebarContent } from 'react-pro-sidebar';
//import 'react-pro-sidebar/dist/css/styles.css';

import Home from './Pages/Home';
import Loopbaan from './Pages/Loopbaan';
import Contact from './Pages/Contact';
import Beelden from './Pages/Beelden';
import Expositie from './Pages/Expositie';
//import Navigation from './Components/Navigation';

export default function Layout() {
  return (
        <div className='main'>
          <div className='page-wrapper'>
            {/* <div className='sidebar-container'>             */}
              {/* <div className='sidebar-column'> */}
                  <Navigation/>
              {/* </div> */}
            {/* </div> */}
            <div className='page-column'>
              <Routes>
                <Route path="/loopbaan" element={<Loopbaan/>} />
                <Route path="/contact" element={<Contact/>} />
                <Route path="/beelden" element={<Beelden/>} />
                <Route path="/expositie" element={<Expositie/>} />
                <Route path="/" element={<Home/>} />
              </Routes>
            </div>
          </div>
        </div>
  );
}